<template>
  <div :class="isCard ? 'DropdownSearch w-card' : ''" >
    <Dropdown
      :text="text"
      :icon="icon"
    >
      <Input
        id="searchIwefsd"
        type="text"
        :placeholder="$i18n.t('cp_search')"
        @handlerInputChange="handleSearch"
        :value="inputValue"
      />
      <hr />
      <template
        v-for="item in items"
      >
        <b-dropdown-item-button
          v-if="hasItems"
          @click.native="handleSelectItem(item)"
          :key="item.id"
        >
          <IconGroup
            :icon="getIcon(item.avatar)"
            :label="item.name"
          />
          <div class="sp__v"/>
        </b-dropdown-item-button>
      </template>

      <b-dropdown-item-button
        v-if="!isLoading"
        @click.native="handleAction"
      >
        <div class="d-flex">
          <img src="@/assets/components/plus-primary.svg" />
          <div class="sp__1s5x"/>
          <span class="text-primary">{{ actionText }}</span>
        </div>
      </b-dropdown-item-button>
      <Spinner
        :isLoading="isLoading"
        variant="primary"
      />
      <div class="sp__v"/>
    </Dropdown>
    <div class="Dropdown__list">
      <MultiBadge
        v-for="(item, index) in getSelectedItems"
        :key="index"
        :item="item"
          @handleDelete="handleDelete(index)"
      />
    </div>
  </div>
</template>

<script>
import Dropdown from '@/components/atoms/Dropdown/Dropdown.vue';
import Input from '@/components/atoms/Input/Input.vue';
import IconGroup from '@/components/atoms/Icon/IconGroup/IconGroup.vue';
import Spinner from '@/components/atoms/Spinner/Spinner.vue';
import MultiBadge from '@/components/atoms/Badge/MultiBadge.vue';
import { getAvatarUrl } from '@/helpers/UserHelper';
import emptyAvatar from '@/assets/user/empty-avatar-dark.svg';

const MIN_SEARCH_LENGHT = 3;

export default {
  props: {
    text: {
      type: String,
      default: '',
    },
    icon: {
      type: String,
      default: '',
    },
    actionText: {
      type: String,
      required: true,
    },
    items: {
      type: Array,
      default: () => [],
    },
    isCard: {
      type: Boolean,
      default: false,
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
    selectedItems: {
      type: Array,
      default: () => [],
    },
  },
  mounted() {
  },
  data() {
    return {
      inputValue: '',
    };
  },
  methods: {
    handleSearch(text) {
      if (text && text.length >= MIN_SEARCH_LENGHT) {
        this.$emit('handleSearch', text);
        this.inputValue = text;
      }
    },

    handleSelectItem(value) {
      const existItem = this.selectedItems.find((fruta) => fruta.id === value.id);

      if (existItem === undefined) {
        this.selectedItems.push(value);
        this.$emit('handleChange', this.selectedItems);
        this.inputValue = '';
      }
    },

    handleAction() {
      this.$emit('handleAction');
    },

    handleDelete(index) {
      this.selectedItems.splice(index, 1);

      this.$emit('handleChange', this.selectedItems);
    },

    getIcon(avatar) {
      if (avatar !== null && typeof avatar !== 'undefined') {
        return getAvatarUrl(avatar);
      }

      return emptyAvatar;
    },
  },
  computed: {
    getSelectedItems() {
      return this.selectedItems;
    },

    hasItems() {
      return this.items.length > 0;
    },
  },
  components: {
    Dropdown,
    Input,
    IconGroup,
    Spinner,
    MultiBadge,
  },
};
</script>

<style lang="sass" scoped>
  @import './Dropdown.sass'
</style>
