<template>
  <SurveyForm
    id="create-survey"
    :title="$i18n.t('btn_send_survey')"
    @handleOk="create"
    @handleCancel="handleCancel"
    :isLoading="isLoading"
    :patientsList="patientsList"
    :survey="survey"
    :surveyType="surveyType"
  />
</template>

<script>
import SurveyForm from '@/components/organisms/Survey/SurveyForm.vue';

export default {
  props: {
    patientsList: {
      type: Array,
      default: () => [],
    },
    survey: {
      type: Object,
      default: () => null,
    },
    surveyType: {
      type: Object,
      default: () => null,
    },
  },
  data() {
    return {
      isLoading: false,
    };
  },
  mounted() {
    this.$bvModal.show('create-survey');
  },
  methods: {
    create(data) {
      const newData = {
        id: data.id,
        sendById: data.sendBy,
        patients: this.patientsList,
        surveyTypeId: data.surveyType.id,
        requestId: data.request?.id ?? null,
      };
      this.isLoading = true;

      this.$store.dispatch('survey/createSurvey', newData)
        .then((res) => {
          const resData = data;
          resData.id = res.id;
          this.$emit('addSurvey', resData);
          this.isLoading = false;
        })
        .catch(() => {
          this.isLoading = false;
        });
    },

    handleCancel() {
      this.$emit('handleCancel');
    },
  },
  components: {
    SurveyForm,
  },
};
</script>
