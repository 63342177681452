<template>
  <ModalAction
    :id="id"
    :okLabel="`${$i18n.t('btn_send_survey')}`"
    :isLoading="isLoading"
    @handleOk="handleOk"
    @handleImport="handleShowImportModal"
    @handleCancel="handleCancel"
    isForm
    onlyOk
    withImport
    footerSeparator
  >

  <b-form>
    <b-row>
      <b-col>
        <p class="text__title">{{ title }}</p>
      </b-col>
    </b-row>
    <div class="sp__v__2x" />
    <b-row>
      <b-col class="col-5">
        <p class="text__psb">{{ `${$i18n.t('lb_patient')}:` }}</p>
        <div class="sp__v" />
        <DropdownSearchMultiple
          :text="getPatientDetail"
          :icon="this.surveyData.patient.image ||
            require('@/assets/user/empty-avatar-dark.svg')"
          :actionText="$i18n.t('lb_new_patient')"
          :items="patientItems"
          :selectedItems="patientsList"
          :isLoading="isPatientDropdownLoading"
          :isPatient="isPatient"
          :has-action="false"
          @handleSearch="handleSearchPatient"
          @handleSelectItem="handleSelectPatient"
          @handleAction="handleShowCreatePatient"
          :isValid="getValidationState.patients"
        />
        <CreatePatient
          v-if="showCreatePatient"
          @addPatient="addPatient"
          @handleCancel="closeCreatePatient"
        />
      </b-col>
      <b-col class="col-5">
        <p class="text__psb">{{ `${$i18n.t('lb_survey_type')}:` }}</p>
        <div class="sp__v" />
        <div class="dropdown__BV_toggle_">
          <Select2
            v-model="myValue"
            :options="this.myOptions"
            :placeholder="$i18n.t('lb_select_survey_type')"
            class="select2-custom"
            max-width="250px"
            :settings="{ minimumResultsForSearch: -1}"
            @change="myChangeEvent($event)"
            @select="mySelectEvent"
            :disabled="isSurveySet"
          />
        </div>
        <a class="link-preview-survey" v-if="surveyData.surveyType.id"
           target="_blank" rel="noopener noreferrer"
           :href="`/surveyType/${surveyData.surveyType.id}`">Previsualizar encuesta</a>
      </b-col>
    </b-row>
    <div class="sp__v" />
  </b-form>
  </ModalAction>
</template>

<script>
import { mapGetters } from 'vuex';
import Select2 from 'v-select2-component';
import ModalAction from '@/components/molecules/Modal/ModalAction.vue';
import { getValidationState, isValidForm } from '@/helpers/ValidationHelper';
import PatientModel from '@/domain/patient/PatientModel';
import SurveyTypeModel from '@/domain/surveyType/SurveyTypeModel';
import DropdownSearchMultiple from '@/components/atoms/Dropdown/DropdownSearchMultiple.vue';
import { getImageFormatted } from '@/helpers/ComponentHelper';

export default {
  props: {
    id: {
      type: String,
      required: true,
    },
    survey: {
      type: Object,
      default: () => {},
    },
    surveyType: {
      type: Object,
      default: () => null,
    },
    title: {
      type: String,
      required: true,
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
    validationState: {
      type: Object,
      default: () => ({
        patient: null,
      }),
    },
    patientsList: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      myValue: this.surveyType?.id || '',
      myOptions: [],
      surveyData: {
        id: this.survey?.id || this.surveyType?.id,
        sendBy: this.survey?.sendBy,
        patient: {
          id: this.survey?.id,
          created: this.survey?.created,
          name: this.survey?.name,
          lastname: this.survey?.lastname,
          gender: this.survey?.gender,
          origin: this.survey?.origin,
          dni: this.survey?.dni,
          dob: this.survey?.dob,
          tutor: this.survey?.tutor,
          insurance: this.survey?.insurance,
          hospital: this.survey?.hospital,
          email: this.survey?.email,
          phone: this.survey?.phone,
          requests: this.survey?.requests,
          contactWhatsApp: this.survey?.contactWhatsApp,
        },
        surveyType: {
          id: this.survey?.id || this.surveyType?.id,
          name: this.survey?.name || this.surveyType?.name,
        },
        request: this.survey?.request,
      },
      isPatientDropdownLoading: false,
      isSurveyTypeDropdownLoading: false,
      isPatient: true,
      showCreatePatient: false,
      showCreateSurveyType: false,
      patientItems: [],
      surveyTypeItems: [],
      lastPatientId: null,
      lastSurveyTypeId: null,
    };
  },
  created() {
    this.getSurveyTypeNames();
  },
  mounted() {
  },
  methods: {
    handleShowImportModal() {
      this.$bvModal.show('requestFormImportPatientModal');
    },
    getSurveyTypeNames() {
      this.$store.dispatch('surveyType/getAllSurveyType', {
        uuid: this.user.id,
        page: 1,
        itemsPerPage: 1000,
        language: this.user.defaultLanguage,
      }).then(
        (res) => {
          const options = Object.keys(res.surveyTypeList).map((surveyType) => {
            const newSurveyType = {
              id: res.surveyTypeList[surveyType].id,
              text: res.surveyTypeList[surveyType].name,
            };
            return newSurveyType;
          });
          this.myOptions = options;
        },
      ).catch(() => {});
    },
    myChangeEvent(val) {
      this.handleShowCreateSurveyType(val);
    },
    mySelectEvent(data) {
      this.handleSelectSurveyType(data);
    },
    getFilters(text) {
      const filtersGroup = [];

      if (text !== null) {
        filtersGroup.push(
          `name:${text}`,
        );
      }

      return filtersGroup;
    },
    handleSearchPatient(text) {
      if (text !== null) {
        this.isPatientDropdownLoading = true;

        this.$store.dispatch('patient/searchPatient', this.getFilters(text))
          .then((res) => {
            this.patientItems = res.patientList;
            this.isPatientDropdownLoading = false;
          })
          .catch(() => {
            this.isPatientDropdownLoading = false;
          });
      }
    },
    handleSelectPatient(value) {
      if (!this.lastPatientId) {
        this.lastPatientId = this.surveyData.patient.id;
      }

      const newPatient = new PatientModel({
        id: value.id,
        created: value.created,
        name: value.name,
        lastname: value.lastname,
        gender: value.gender,
        origin: value.origin,
        dni: value.dni,
        dob: value.dob,
        tutor: value.tutor,
        insurance: value.insurance,
        hospital: value.hospital,
        email: value.email,
        phone: value.phone,
        requests: value.requests,
        contactWhatsApp: value.contactWhatsApp,
      });

      this.surveyData.patient = newPatient;

      this.patientItems = [];
    },
    handleShowCreatePatient() {
      this.showCreatePatient = true;
    },
    closeCreatePatient() {
      this.showCreatePatient = false;
    },
    addPatient(data) {
      this.patientsList.unshift(this.getCreateData(data));

      this.closeCreatePatient();
    },
    getCreateData(data) {
      const newData = data;
      newData.image = getImageFormatted(data.image);

      return newData;
    },
    handleSearchSurveyType() {
      this.isSurveyTypeDropdownLoading = true;

      this.$store.dispatch('surveyType/getAllSurveyType', {
        uuid: this.user.id,
      })
        .then((res) => {
          this.surveyTypeItems = res.surveyTypeList;
          this.isSurveyTypeDropdownLoading = false;
        })
        .catch(() => {
          this.isSurveyTypeDropdownLoading = false;
        });
    },
    handleSelectSurveyType(value) {
      if (!this.lastSurveyTypeId) {
        this.lastSurveyTypeId = this.surveyData.surveyType.id;
      }

      const newSurveyType = new SurveyTypeModel({
        id: value.id,
        name: value.text,
        templatePath: value.templatePath,
        createdByData: value.createdByData,
        createdAt: value.createdAt,
        updatedAt: value.updatedAt,
        deletedAt: value.deletedAt,
      });
      this.surveyData.surveyType = newSurveyType;
      this.surveyData.patientsList = this.patientsList;

      this.surveyTypeItems = [];
    },
    handleShowCreateSurveyType() {
      this.showCreateSurveyType = true;
    },
    sendOk() {
      this.surveyData.sendBy = this.user.uuid;
      this.surveyData.patientsList = this.patientsList;
      this.$emit('handleOk', this.surveyData);
    },

    handleOk() {
      const patientsValidationState = getValidationState(
        this.patientsList,
      );
      if (isValidForm([
        patientsValidationState,
      ])) {
        this.sendOk();
      } else {
        this.validationState.patients = patientsValidationState;
      }
    },

    handleCancel() {
      this.resetValidations();
      this.$emit('handleCancel');
    },
    resetValidations() {
      this.validationState.patients = [];
    },
  },
  computed: {
    ...mapGetters('user', {
      user: 'getCurrentUser',
      isVidasprime: 'isVidasprime',
      isClinic: 'isClinic',
      isHealthManager: 'isHealthManager',
      isDoctor: 'isDoctor',
    }),
    getPatientDetail() {
      return this.surveyData.patient.name
      || (this.surveyData.patient.name && this.surveyData.patient.lastname)
        ? `${this.surveyData.patient.name} ${this.surveyData.patient.lastname}`
        : this.$i18n.t('lb_select_patient');
    },
    getSurveyTypeDetail() {
      return this.surveyData.surveyType.name
        ? this.surveyData.surveyType.name
        : this.$i18n.t('lb_select_survey');
    },
    getValidationState() {
      return this.validationState;
    },
    isSurveySet() {
      return this.surveyType !== null;
    },
  },
  watch: {
    survey(newValue) {
      this.surveyData.id = newValue.id;
      this.surveyData.sendBy = newValue.sendBy;
      this.surveyData.patient = newValue.patient;
      this.surveyData.surveyType = newValue.surveyType;
      this.surveyData.request = newValue.request;
    },
  },
  components: {
    DropdownSearchMultiple,
    Select2,
    ModalAction,
    CreatePatient: () => import('@/components/organisms/Patient/CreatePatient.vue'),
  },
};
</script>
